<template>
  <div class="single-system__layers">
    <div
      v-for="(layer, i) in parentLayers"
      :key="`layer-${i}`"
      tabindex="1"
      class="single-layer"
      @focusin="expandLayers(i)"
      :class="{ 'single-layer--highlight': highlightIndex === i }"
      :ref="`layer${i}`"
    >
      <div class="single-layer__header">
        <div class="single-layer__header-name">
          <div class="text" :class="{ 'text--disabled': layer.wedge && !layer.isEnabled }">
            {{ layer.name }}
          </div>
          <Layer-toggler
            v-if="!layer.immutable && !layer.isBaseLayer"
            :layerIndex="i"
            :isEnabled="layer.isEnabled"
            :id="`layer-toggler-${layer.id}-${i}`"
          />
          <!-- v-if="isLayerHasToggler(layer) && !layer.isBaseLayer" -->
        </div>
      </div>
      <div class="single-layer__body">
        <div
          class="single-layer__body-item"
          v-for="(item, n) in layer.items"
          :key="`child-layer-${i}-number-${n}-computed-${getCurrentNumber(i, n)}`"
        >
          <div class="number" :class="{ 'number--expanded': isLayerHasFastener(item) }">
            <div class="number__text">
              {{ getCurrentNumber(i, n) }}
            </div>
            <!-- <Fastener-toggler
              v-if="isLayerHasFastener(item)"
              :id="`layer-${getCurrentNumber(i, n)}`"
              :currentChecked="currentFastenerInfo"
              @showFastenerInfo="showFastenerInfo"
            /> -->
          </div>
          <div
            class="elements-base"
            v-if="isBaseLayer(layer)"
            :class="{ 'elements-base--disabled': !item.hasAlternate }"
          >
            <!--<BaseTypeSelect :baseTypeId="item.baseTypeId"
                            :layerIndex="i"
                            :itemIndex="n"
                            @showProducts="request($event, 'base', layer.name, i, n)"/>-->
            <div class="elements-base__head">
              {{ item.name }}
            </div>
            <div class="elements-base__body">
              <!--<div class="layer-select"
                   :class="{'layer-select&#45;&#45;disabled': !item.hasAlternate}"
                   @click="choseNewMaterial(layer.name, item.name, item.hasAlternate, i, n)">
                {{ item.material.name }}
              </div>-->
              <div
                class="layer-select"
                :class="{ 'layer-select--disabled': !item.hasAlternate }"
                @click="item.hasAlternate ? request(item.name, 'usual', layer.name, i, n) : null"
              >
                {{ item.material.name }}
              </div>
              <Input-or-select
                :item="item"
                v-if="isShowInputOrSelect(item.material)"
                :layerIndex="i"
                :parent-type="'system'"
                :isInCustom="false"
                :itemIndex="n"
              />
            </div>
          </div>
          <div class="elements" v-else>
            <!--<div class="elements__left"
                 @click="choseNewMaterial(layer.name, item.name, item.hasAlternate, i, n)"
                 :class="{'elements__left&#45;&#45;full': !item.material.valueType,
                          'elements__left&#45;&#45;disabled': !item.hasAlternate
                          }">-->
            <div
              class="elements__left"
              @click="item.hasAlternate ? request(item.name, 'usual', layer.name, i, n) : null"
              :class="{
                'elements__left--full': !isShowInputOrSelect(item.material),
                'elements__left--disabled': !item.hasAlternate
              }"
            >
              <div class="elements__left-info">
                <div class="name">{{ item.name }}</div>
                <div class="material">{{ item.material.name }}</div>
              </div>
              <div class="chevron">
                <img
                  class=""
                  src="@/assets/img/icons/filter--select.svg"
                  alt="↓"
                  v-if="item.hasAlternate"
                />
                <img
                  class=""
                  src="@/assets/img/icons/filter--select--disabled.svg"
                  alt="↓"
                  v-else
                />
              </div>
            </div>
            <Input-or-select
              :item="item"
              v-if="isShowInputOrSelect(item.material)"
              :layerIndex="i"
              :parent-type="'system'"
              :isInCustom="false"
              :itemIndex="n"
            />
          </div>
          <div class="elements elements--fastener" v-if="isLayerHasFastener(item)">
            <!-- <Fastener-toggler
              :id="`layer-${getCurrentNumber(i, n)}`"
              :currentChecked="currentFastenerInfo"
              @showFastenerInfo="showFastenerInfo"
            /> -->
            <fastener-info
              :is-wedge="item.wedge"
              :itemIndex="n"
              :layerIndex="i"
              :fastenerId="item.fasteners.selected"
              :base="findBaseType(getFastenerParams(i, n).fastenerBase)"
              :height="getFastenerParams(i, n).fastenerHeight"
              :rate="item.computedFastenersRate"
              :isShowCheckBox="isKlinInWedgeExist"
              :currentChecked="currentFastenerInfo"
              :available-fasteners="item.fasteners.options"
              :id="`layer-${getCurrentNumber(i, n)}`"
              @showFastenerInfo="showFastenerInfo"
              :is-rate-mutable="item.fasteners.mutable"
              :is-in-custom="false"
            />
          </div>
        </div>
        <!-- <Item-add
          :layerIndex="i"
          :layerId="layer.id"
          :available-items="getAvailableItems(layer.items)"
          placement="system"
          v-if="isShowItemAddBtn(layer.id)"
          @getNewLayer="getNewLayer($event, layer.name, i, layer.items.length)"
        />-->
      </div>
    </div>
    <Modal
      :isModalOpen="isModalOpen"
      :isCloseInHeader="true"
      :isNoRadius="true"
      :isShowClose="isShowCloseInModal"
      :can-close="isCanClose"
      @close="close"
    >
      <template #body>
        <LayerProductsView
          :path="layersPath"
          :layerIndex="layerIndexToSelect"
          :itemIndex="itemIndexToSelect"
          :placement="'system'"
          :response="responseItems"
          @close="close"
        />
      </template>
    </Modal>
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      color="#E30713"
      :height="145"
      :width="145"
      :opacity="0.7"
      backgroundColor="#eeeeee"
      :z-index="3"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { cloneDeep } from 'lodash'
import {
  getItemNumber,
  getFastenerInfo,
  isLayerHasFastener,
  isTargetLayerInWedgeExist,
  isLayerHasToggler,
  isShowElement
} from '@/utils/customFunctions'
// import { scrollToLayer } from "@/utils/drawing";
import Modal from '@/components/elements/Modals/Modal'
import LayerProductsView from '@/components/smart/layersProducts/ProductSelection'
import LayerToggler from '@/components/elements/Layers/LayerToggler'
import InputOrSelect from '@/components/elements/Dom/InputOrSelect'
// import BaseTypeSelect from "@/elements/Dom/BaseTypeSelect";
import { getMaterialsForLayer } from '@/api'
import FastenerInfo from '@/components/elements/Layers/Fastener-info'
// import ItemAdd from '@/components/elements/Layers/ItemAdd'

export default {
  props: {
    highlightIndex: {
      type: Number,
      default: null
    }
  },
  data: () => ({
    currentFastenerInfo: '',
    isModalOpen: false,
    layersPath: [],
    layerIndexToSelect: '',
    itemIndexToSelect: '',
    isShowCloseInModal: true,
    isLoading: false,
    responseItems: {},
    isCanClose: false
  }),
  components: {
    FastenerInfo,
    // ItemAdd,
    Modal,
    LayerProductsView,
    LayerToggler,
    InputOrSelect
    // BaseTypeSelect,
  },
  // watch: {
  //   highlightIndex() {
  //     if (this.highlightIndex !== null) {
  //       const target = this.$refs[`layer${this.highlightIndex}`][0].offsetTop
  //       scrollToLayer(target);
  //       // for (let key in this.$refs) {
  //       //   this.$refs[key][0].removeAttribute('tabindex');
  //       // }
  //       // const layer = this.$refs[`layer${this.highlightIndex}`][0];
  //       // highlightLayer(layer, this.highlightIndex);
  //     }
  //   }
  // },
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_SYSTEM_LAYER_ITEM_VALUE: 'UPDATE_SECTOR_SYSTEM_LAYER_ITEM_VALUE',
      UPDATE_SECTOR_SYSTEM_LAYER_ENABLE_STATUS: 'UPDATE_SECTOR_SYSTEM_LAYER_ENABLE_STATUS',
      ADD_CHILD_LAYER_TO_SYSTEM: 'ADD_CHILD_LAYER_TO_SYSTEM',
      EXPAND_LAYERS: 'EXPAND_LAYERS'
    }),
    // isShowItemAddBtn(id) {
    //   return id === 229
    // },
    // getAvailableItems(items) {
    //   return uniq(items.map(item => item.id))
    // },
    // getNewLayer(newLayer, layer, layerIndex, itemIndex) {
    //   this.ADD_CHILD_LAYER_TO_SYSTEM({
    //     sectorIndex: this.sectorIndex,
    //     layerIndex: layerIndex,
    //     layer: newLayer
    //   })
    //   this.isLoading = true
    //   getMaterialsForLayer(this.$i18n.locale, 'system', null, null, 'custom', newLayer.id).then(
    //     response => {
    //       // console.log(response);
    //       this.isLoading = false
    //       this.isModalOpen = !this.isModalOpen
    //       this.layerIndexToSelect = layerIndex
    //       this.itemIndexToSelect = itemIndex
    //       this.isCanClose = false
    //       this.layersPath = [layer, newLayer.name]
    //       // this.responseItems = this.layersProducts;
    //       this.responseItems = response.data
    //     }
    //   )
    // },
    expandLayers(layerIndex) {
      if (!this.parentLayers[layerIndex].expanded) {
        this.$emit('highlightLayer', { layerIndex: layerIndex, placement: 'table' })
        // this.EXPAND_LAYERS({
        //   sectorIndex: this.sectorIndex,
        //   layerIndex: layerIndex,
        //   // value: layerIndex === null ? false : true
        //   value: true
        // })
      }
    },
    isLayerHasToggler(layer) {
      return isLayerHasToggler(layer)
    },
    isLayerHasFastener(item) {
      return isLayerHasFastener(item)
    },
    close() {
      this.isModalOpen = false
    },
    request(itemName, placement, layerName, layerIndex, itemIndex) {
      this.isLoading = true
      getMaterialsForLayer(
        this.$i18n.locale,
        'system',
        layerIndex,
        itemIndex,
        'stock',
        this.sectorFromState.system
      ).then(response => {
        // console.log(response);
        this.isLoading = false
        this.isModalOpen = !this.isModalOpen
        this.layerIndexToSelect = layerIndex
        this.itemIndexToSelect = itemIndex
        this.responseItems = response.data
        // this.responseItems = this.layersProducts;
        this.isShowCloseInModal = placement !== 'base'
        this.isCanClose = placement !== 'base'
        this.layersPath = [layerName, itemName]
      })
    },
    isShowInputOrSelect(material) {
      return isShowElement(material)
    },
    getCurrentNumber(parentIteration, childIteration) {
      return getItemNumber(parentIteration, childIteration, this.parentLayers)
    },
    getFastenerParams(totalIndex, localIndex) {
      let arr = cloneDeep(this.parentLayers)
      return getFastenerInfo(totalIndex, localIndex, arr, this.defaultBaseId)
    },
    isBaseLayer(layer) {
      return Object.prototype.hasOwnProperty.call(layer, 'isBaseLayer')
    },
    showFastenerInfo(id) {
      this.currentFastenerInfo = id
    },
    findBaseType(id) {
      const target = this.baseTypes.find(p => p.id === id)
      return target ? target.name : this.baseTypes.find(p => p.id === this.defaultBaseId).name
    }
  },
  computed: {
    ...mapState({
      layersProducts: state => state.layersProducts,
      baseTypes: state => state.baseTypes,
      defaultBaseId: state => state.defaultBaseId
    }),
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    parentLayers() {
      return this.sectorFromState.layers
    },
    isKlinInWedgeExist() {
      return isTargetLayerInWedgeExist(this.sectorFromState.layers, 'wedge')
    }
  }
}
</script>
<style lang="sass" scoped>
// @import "~@/assets/sass/_layers.sass"
//
</style>
<style lang="sass">
.fastener-info__attach-tooltip
  padding: rem(11)
  text-align: left
  font-size: rem(12)
  line-height: 150%
  display: block
  font-weight: 500
</style>
