<template>
  <div class="custom-toggler">
    <input type="checkbox" :id="id" v-model="computedIsEnabled" class="custom-toggler__input" />
    <label :for="id" class="custom-toggler__label">
      <span class="pop"></span>
    </label>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  isSomeWedgeIsEnabled,
  isTargetLayerInWedgeExist,
  isSomeFastenerAttachWedge
} from '@/utils/customFunctions'

export default {
  props: {
    id: String,
    layerIndex: Number,
    isEnabled: Boolean
  },
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_SYSTEM_LAYER_ENABLE_STATUS: 'UPDATE_SECTOR_SYSTEM_LAYER_ENABLE_STATUS'
      // UNCHECK_ALL_SECTOR_SYSTEM_LAYERS_ITEMS_FASTENER_ATTACH: 'UNCHECK_ALL_SECTOR_SYSTEM_LAYERS_ITEMS_FASTENER_ATTACH'
    })
  },
  computed: {
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    isSomeWedgeIsEnabled() {
      return isSomeWedgeIsEnabled(this.sectorFromState.layers)
    },
    isKlinInWedgeExist() {
      return isTargetLayerInWedgeExist(this.sectorFromState.layers, 'wedge')
    },
    isSomeFastenerAttachWedge() {
      return isSomeFastenerAttachWedge(this.sectorFromState.layers)
    },
    computedIsEnabled: {
      get() {
        return this.isEnabled
      },
      set(value) {
        this.UPDATE_SECTOR_SYSTEM_LAYER_ENABLE_STATUS({
          sectorIndex: this.sectorIndex,
          layerIndex: this.layerIndex,
          value: value
        })
        // if (!this.isKlinInWedgeExist && this.isSomeFastenerAttachWedge) {
        //   this.UNCHECK_ALL_SECTOR_SYSTEM_LAYERS_ITEMS_FASTENER_ATTACH(this.sectorIndex);
        // }
      }
    }
  }
}
</script>

<style lang="sass">
.custom-toggler
  background: #fff
  &__input
    position: absolute
    pointer-events: none
    display: none
    opacity: 0
    &:checked
      + label
        .pop
          left: rem(18)
          background-color: $green
          background-image: url(~@/assets/img/icons/check.svg)
  &__label
    background: #FFFFFF
    border: rem(1) solid #D6D6D6
    box-sizing: border-box
    border-radius: rem(100)
    width: rem(40)
    height: rem(24)
    display: block
    position: relative
    cursor: pointer
    .pop
      height: rem(16)
      width: rem(16)
      background-color: $red
      background-position: center
      background-repeat: no-repeat
      border-radius: 50%
      display: block
      position: absolute
      left: rem(4)
      top: 50%
      transition: .3s
      transform: translateY(-50%)
</style>
