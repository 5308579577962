<template>
  <transition name="modal">
    <div class="scheme-modal" v-if="isModalOpen">
      <div class="container">
        <div class="scheme-modal__title major-title">
          {{ title }}
          <button @click="close" class="close">
            <img src="@/assets/img/icons/uploader--modal-close.svg" alt="❌" />
          </button>
        </div>
        <SingleSystemSchemeImage :is-in-full-screen="true" ref="schemeComponent" />
      </div>
    </div>
  </transition>
</template>

<script>
import SingleSystemSchemeImage from '@/components/smart/System/Scheme/SingleSystemSchemeImage'
export default {
  props: {
    isModalOpen: {
      type: Boolean,
      default: false
    },
    title: String
  },
  methods: {
    close() {
      this.$emit('close')
    }
  },
  watch: {
    isModalOpen() {
      this.isModalOpen ? this.$root.offBodyScroll() : this.$root.onBodyScroll()
    }
  },
  components: {
    SingleSystemSchemeImage
  }
}
</script>

<style scoped lang="sass">
.scheme-modal
  height: 100vh
  width: 100vw
  position: fixed
  top: 0
  left: 0
  background: $default
  z-index: 12
  overflow-y: auto
  padding-bottom: rem(40)
  box-sizing: border-box
  &__title
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    padding: rem(68) 0 0
    .close
      @extend .clear-btn
      width: rem(80)
      height: rem(48)
.modal-enter-active, .modal-leave-active
  transition: .5s
.modal-enter, .modal-leave-to
  -webkit-transform: scale(1.1)
  opacity: 0
  transform: scale(1.1)
</style>
