<template>
  <div class="elements-base__head">
    <div class="layer-select">
      <select name="cat" v-model="computedBaseType" class="select">
        <option v-for="bt in baseTypes" :value="bt.id" :key="`bt-${bt.id}`">{{ bt.name }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  props: {
    baseTypeId: Number,
    layerIndex: Number,
    itemIndex: Number
  },
  methods: {
    ...mapMutations({
      UPDATE_SECTOR_SYSTEM_BASE_LAYER_TYPE: 'UPDATE_SECTOR_SYSTEM_BASE_LAYER_TYPE'
    })
  },
  computed: {
    ...mapState({
      // baseTypes: state => state.baseTypes,
      layersList: state => state.layersList
    }),
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    baseTypes() {
      return this.layersList.find(p => p.isBaseLayer).items
    },
    computedBaseType: {
      get() {
        return this.baseTypeId
      },
      set(value) {
        // console.log(value)
        const baseTypeName = this.baseTypes.find(p => p.id === value).name
        this.UPDATE_SECTOR_SYSTEM_BASE_LAYER_TYPE({
          sectorIndex: this.sectorIndex,
          layerIndex: this.layerIndex,
          itemIndex: this.itemIndex,
          id: value,
          name: baseTypeName
        })
        this.$emit('showProducts', baseTypeName)
      }
    }
  }
}
</script>

<style scoped lang="sass">
.elements-base
  &__head
  .layer-select
    border: none
    width: 100%
    .select
      padding: 0
    &:after
      content: ""
      position: absolute
      top: 50%
      transform: translateY(-50%)
      right: rem(12)
      height: rem(10)
      width: rem(10)
      background-image: url(~@/assets/img/select.svg)
      background-position: center
      background-repeat: no-repeat
      pointer-events: none
      background-size: contain
    &--disabled
      &:after
        background-image: url(~@/assets/img/icons/select--disabled.svg)
</style>
