<template>
  <section class="single-system__page" v-if="!!allSystems.length">
    <div class="container">
      <router-link :to="`/${this.$i18n.locale}/systems/${this.sectorIndex}`" class="link__back">
        <img src="@/assets/img/icons/breadcrumbs--back.svg" alt="<" class="icon" />
        {{ $t('message.system.breadcrumb') }}
      </router-link>
      <div class="major-title single-system__page-title" v-if="!sectorFromState.isCustom">
        {{ currentSectorSystemName }}
      </div>
      <Change-name :name="sectorFromState.customSystemName" :placement="'system'" v-else />
      <SingleSystemHeadPane ref="headPane" />
      <div class="content__panes-info">
        <img src="@/assets/img/icons/info.svg" alt="i" />
        <div class="text">
          {{ $t('message.system.info') }}
        </div>
      </div>
      <div class="single-system__page-body">
        <component
          :is="sectorFromState.systemType"
          :highlightIndex="highlightIndex"
          @highlightLayer="highlightLayer"
          ref="layersTable"
        >
        </component>
        <div class="image">
          <SingleSystemSchemeImage
            @highlightLayer="highlightLayer"
            @fullScreen="fullScreen"
            :is-show-full-screen="true"
            ref="schemeComponent"
          />
        </div>
      </div>
      <div class="step-buttons">
        <button @click="backToSystems" class="btn btn-back">
          <img src="@/assets/img/icons/step--back.svg" alt="←" />
          <span class="btn__text">
            {{ $t('message.stepButtons.choseSystem') }}
          </span>
        </button>
        <button
          @click="goToNextStep"
          class="btn btn-next"
          :class="{ 'btn--disabled': isBtnNextDisabled }"
        >
          <span class="btn__text">{{ getNextStepText() }}</span>
          <img src="@/assets/img/icons/step--next.svg" alt="→" />
        </button>
      </div>
    </div>
    <Fixed-trigger @fixedTriggerClicked="showSidebar" v-if="!isDesktop">
      <template>
        <img src="@/assets/img/icons/system--sidebar.svg" alt="" />
      </template>
    </Fixed-trigger>
    <Modal
      :isModalOpen="isSidebarShow"
      :isShowClose="true"
      :is-no-radius="true"
      v-if="!isDesktop"
      @close="close"
    >
      <template #body>
        <div class="sidebar--mobile">
          <SingleSystemSchemeImage :is-in-mobile-modal="true" />
        </div>
      </template>
    </Modal>
    <Scheme-modal
      :isModalOpen="isSchemeModalShow"
      :is-in-full-screen="true"
      :title="sectorFromState.isCustom ? sectorFromState.customSystemName : currentSectorSystemName"
      @close="isSchemeModalShow = false"
    />
  </section>
</template>

<script>
import { scrollAndHighLightErrorField } from '@/utils'
import SingleSystemHeadPane from '@/components/smart/System/SingleSystemHeadPane'
import DefaultSystem from '@/components/smart/System/SingleSystemLayers'
import CustomSystem from '@/components/smart/System/SingleSystemLayersCustom'
import ChangeName from '@/components/smart/ChangeName'
import SingleSystemSchemeImage from '@/components/smart/System/Scheme/SingleSystemSchemeImage'
import Modal from '@/components/elements/Modals/Modal'
import FixedTrigger from '@/components/elements/Dom/FixedTrigger'
import SchemeModal from '@/components/elements/Modals/SchemeModal'
import device from 'current-device'
import { mapState, mapMutations, mapActions } from 'vuex'
import { scrollToLayer } from '@/utils/drawing'
import { stepNumbers } from '@/utils/stepNumbers'
import {
  isWedgeExist,
  isSomeWedgeIsEnabled,
  isTargetLayerInWedgeExist
} from '@/utils/customFunctions'

export default {
  data: () => ({
    isEditable: false,
    highlightIndex: null,
    isSidebarShow: false,
    isSchemeModalShow: false,
    isDesktop: ''
  }),
  components: {
    SingleSystemHeadPane,
    DefaultSystem,
    CustomSystem,
    ChangeName,
    SingleSystemSchemeImage,
    FixedTrigger,
    Modal,
    SchemeModal
  },
  methods: {
    ...mapActions({
      updateStep: 'updateStep'
    }),
    ...mapMutations({
      UPDATE_SECTOR_PARAM: 'UPDATE_SECTOR_PARAM',
      EXPAND_LAYERS: 'EXPAND_LAYERS',
      COLLAPSE_LAYERS: 'COLLAPSE_LAYERS'
    }),
    prepareSchemeCode() {
      // const string = this.$refs.schemeComponent.$refs.schemeSvg.outerHTML
      // // const regexp = /content.+>"/g
      // const regexp = /content=([\\'"])([^"]*)([\\'"])/g
      // console.log(string.replace(regexp, ''))
    },
    showSidebar() {
      this.isSidebarShow = true
    },
    close() {
      this.isSidebarShow = false
    },
    focusInput() {
      this.isEditable = true
      this.$refs.title__input.focus()
    },
    updateCustomSystemName(e) {
      this.UPDATE_SECTOR_PARAM({
        index: this.sectorIndex,
        param: 'customSystemName',
        value: e.target.value
      })
    },
    isWedgeExist() {
      return isWedgeExist(this.sectorFromState.layers)
    },

    backToSystems() {
      this.$router.push(`/${this.$i18n.locale}/systems/${this.sectorIndex}`)
    },
    goToNextStep() {
      if (this.isBtnNextDisabled) {
        const target = this.$refs.headPane.$refs.square
        scrollAndHighLightErrorField(target)
      } else {
        if (this.isKlinInWedgeExist) {
          this.$router.push(`/${this.$i18n.locale}/wedge/${this.sectorIndex}`)
        } else {
          this.$router.push(`/${this.$i18n.locale}/junctions/${this.sectorIndex}`)
        }
      }
    },
    getNextStepText() {
      return this.isKlinInWedgeExist
        ? `${this.$i18n.t('message.stepButtons.setUpWedge')}`
        : `${this.$i18n.t('message.stepButtons.addJunction')}`
    },
    highlightLayer(obj) {
      // console.log(obj)
      if (this.isDesktop) {
        const layerIndex = obj.layerIndex
        const placement = obj.placement
        this.highlightIndex = this.highlightIndex === layerIndex ? null : layerIndex
        // this.highlightIndex = layerIndex
        if (!this.sectorFromState.layers[layerIndex].expanded) {
          this.EXPAND_LAYERS({
            sectorIndex: this.sectorIndex,
            layerIndex: layerIndex,
            value: layerIndex !== null
          })
        }

        if (this.highlightIndex === null) {
          this.COLLAPSE_LAYERS(this.sectorIndex)
        }

        if (this.highlightIndex !== null && placement === 'scheme') {
          const target = this.$refs.layersTable.$refs[`layer${this.highlightIndex}`][0].offsetTop
          scrollToLayer(target)
        }
      }
    },
    fullScreen() {
      this.COLLAPSE_LAYERS(this.sectorIndex)
      this.isSchemeModalShow = !this.isSchemeModalShow
    }
  },
  mounted() {
    if (!this.allSystems.length) {
      this.$router.push(`/${this.$i18n.locale}/`)
    }
    device.desktop() ? (this.isDesktop = true) : (this.isDesktop = false)
    this.updateStep(stepNumbers.singleSustem)
  },
  computed: {
    ...mapState({
      allSystems: state => state.systems
    }),
    sectorIndex() {
      return this.$route.params.id ? this.$route.params.id : 0
    },
    sectorFromState() {
      return this.$store.getters.getSector(this.sectorIndex)
    },
    currentSectorSystemName() {
      return this.sectorFromState.system !== ''
        ? this.allSystems.find(p => p.id === this.sectorFromState.system).name
        : ''
    },
    isSectorHasSystem() {
      return this.sectorFromState.system !== ''
    },
    isSomeWedgeIsEnabled() {
      return isSomeWedgeIsEnabled(this.sectorFromState.layers)
    },
    isKlinInWedgeExist() {
      return isTargetLayerInWedgeExist(this.sectorFromState.layers, 'wedge')
    },
    isBtnNextDisabled() {
      return this.sectorFromState.square <= 0
    }
  }
}
</script>
<style lang="sass">

.single
  &-system
    &__page
      &-body
        position: relative
        display: grid
        +media((grid-template-columns: (320: 1fr, 960: 7fr rem(64) rem(336))))
        .image
          grid-column-start: 3
          +media((display: (320: none, 960: block)))
</style>
